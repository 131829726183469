import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

// Images
import bgImageMobile from './../../assets/images/bg-mercado-futuro-hero-mobile.png'
import bgImageMd from './../../assets/images/bg-mercado-futuro-hero-md.png'
import bgImageLg from './../../assets/images/bg-mercado-futuro-hero-lg.png'
import bgImageXl from './../../assets/images/bg-mercado-futuro-hero-xl.png'

export const ContentHeader = styled.section`
  background-image: url(${bgImageMobile});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${bgImageMd});
    background-repeat: no-repeat;
    background-position: center right;
  }

  @media ${device.desktopLG} {
    min-height: 533px;
    background-image: url(${bgImageLg});
    background-repeat: no-repeat;
    background-position: -92px 0;
  }

  @media ${device.desktopXL} {
    min-height: 100vh;
    background-image: url(${bgImageXl});
    background-repeat: no-repeat;
    background-position: center right;
  }

  .btn {
     max-width: 457px;
  }
 
  p {
    margin-bottom: 32px;
  }

  .bread-crumb {
    span {
      color: ${grayscale[400]} !important;

      a {
      color: ${grayscale[400]} !important;
      font-weight: 900;

        &:hover {
          color: ${grayscale[400]} !important;
        }
      }
    }
  }
 
`
export const ButtonVideo = styled.button`
  width: 100%;
  position: relative;
  top: 42px;
  right: 32%;
  background: transparent;
  border: none;
  outline: none !important;

  @media ${device.tablet} {
    top: 40px;
    right: 28%;
  }

  @media ${device.desktopLG} {
    top: 67px;
    right: 21%;
  }

  @media ${device.desktopXL} {
    right: 12%;
  }
`
