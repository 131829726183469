import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
   background-color: ${grayscale[100]};
   .btn {
     max-width: none;
   }

  @media ${device.desktopXL} {
    height: 640px;
  }
`
