import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Card, Section } from './style'

const ConfiraQuandoNegociar = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
    return (
      <Section className='py-5 pl-lg-5 pr-lg-4'>
        <div className='container py-xl-4'>
          <div className='row align-items-center justify-content-center pr-lg-4'>
            <div className='col-12'>
              <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 text-center fw-700'>Confira quando negociar</h2>
            </div>
            <Card className='col-12 px-3 my-md-3'>
              <h2 className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-grayscale--500 text-md-center my-3'>Índice</h2>
              <p className='fs-12 fs-md-16 fs-lg-20 lh-14 lh-md-20 lh-lg-25 text-grayscale--500 text-md-center font-citrina text-grayscale--500'>IND e WIN</p>
            </Card>
            <div className='col-12'>
              <div className='row'>
                <div className='col-4 h-100 pl-3 my-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 text-grayscale--500'>Pré-abertura</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center fw-700'>08h55</h3>
                </div>
                <div className='col-4 h-100 border-md-left my-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Início</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>09h00</h3>
                </div>
                <div className='col-4 h-100 border-md-left my-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Fechamento</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>18h25</h3>
                </div>
              </div>
            </div>
            <Card className='col-12 px-3 my-md-3'>
              <h2 className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-grayscale--500 text-md-center my-3'>Bitcoin Futuro</h2>
              <p className='fs-12 fs-md-16 fs-lg-20 lh-14 lh-md-20 lh-lg-25 text-grayscale--500 text-md-center font-citrina text-grayscale--500'>BIT</p>
            </Card>
            <div className='col-12'>
              <div className='row'>
                <div className='col-4 h-100 pl-3 my-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 text-grayscale--500'>Pré-abertura</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center fw-700'>-</h3>
                </div>
                <div className='col-4 h-100 border-md-left my-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Início</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>09h00</h3>
                </div>
                <div className='col-4 h-100 border-md-left my-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Fechamento</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>18h30</h3>
                </div>
              </div>
            </div>
            <Card className='col-12 px-3 my-md-3'>
              <h2 className='fs-14 fs-md-20 fs-lg-24 fs-md-20 lh-17 lh-md-25 lh-md-25 lh-lg-30 text-grayscale--500 text-md-center my-3'>Dólar</h2>
              <p className='fs-12 fs-md-16 fs-lg-20 lh-14 lh-md-20 lh-lg-25 text-grayscale--400 text-md-center font-citrina text-grayscale--500'>DOL e WDO</p>
            </Card>
            <div className='col-12'>
              <div className='row'>
                <div className='col-4 h-100 pl-3 mt-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Pré-abertura</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>08h55</h3>
                </div>
                <div className='col-4 h-100 border-md-left mt-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Início</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>09h00</h3>
                </div>
                <div className='col-4 h-100 border-md-left mt-3'>
                  <p className='fs-12 fs-md-20 fs-lg-24 lh-15 lh-md-25 lh-lg-30 text-md-center fw-400 font-citrina text-grayscale--500'>Fechamento</p>
                  <h3 className='fs-14 fs-md-16 fs-lg-20 fs-xl-28 lh-17 lh-md-20 lh-lg-25 text-md-center mb-0 fw-700'>18h30</h3>
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-8 px-0 pt-md-5'>
              <hr className='d-block d-md-none' />
              <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-md-center fw-400 mb-0'> Para outros ativos,
                <Link
                  className='text-orange--base'
                  to='https://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/horario-de-negociacao/'
                  onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    element_action: 'click button',
                    element_name: 'consulte o site da B3',
                    section_name: 'Confira quando negociar',
                    redirect_url: 'https://www.b3.com.br/pt_br/solucoes/plataformas/puma-trading-system/para-participantes-e-traders/horario-de-negociacao/',
                  })
                  }}
                > consulte o site da B3
                </Link>.
              </p>
              <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-md-center fw-400 my-4 mb-0'>
                Sobre a zeragem: caso o investidor não zere sua posição até 18h15 ou atinja seu limite máximo
                de garantias disponíveis, a área de riscos da Inter DTVM realizará a zeragem compulsória.
              </p>
              <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-md-center fw-400 mb-0'>
                Os horários de operações de dólar estão sujeitos à antecipação de 1h, devido ao horário de verão nos EUA.
              </p>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default ConfiraQuandoNegociar
