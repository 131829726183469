import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  @media ${device.desktopXL} {
    height: 660px;
  }

   .btn {
     max-width: none;
   }
`
