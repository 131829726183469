import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      PlayMercadoFuturo: imageSharp(fluid: {originalName: { regex: "/play-mercado-futuro-hero/" }}) {
        fluid(maxWidth: 128, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      PlayMercadoFuturoTextPreto: imageSharp(fluid: {originalName: { regex: "/play-mercado-futuro-text-preto/" }}) {
        fluid(maxWidth: 128, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      PlayMercadoFuturoVideo: imageSharp(fluid: {originalName: { regex: "/play-mercado-futuro-video/" }}) {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgMercadoFuturoVideoPrimeiro: imageSharp(fluid: {originalName: { regex: "/bg-mercado-futuro-video-primeiro/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgMercadoFuturoVideoSegundo: imageSharp(fluid: {originalName: { regex: "/bg-mercado-futuro-video-segundo/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgMercadoFuturoVideoTerceiro: imageSharp(fluid: {originalName: { regex: "/bg-mercado-futuro-video-terceiro/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgMercadoFuturoVideoQuarto: imageSharp(fluid: {originalName: { regex: "/bg-mercado-futuro-video-quarto/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturomulher: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-mullher/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoCelular: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-celular/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoGrafico: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-grafico/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoToolProfit: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-tool-profit/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoToolTryd: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-tool-tryd/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoToolHbPro: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-tool-hbpro/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoToolAtom: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-tool-atom/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      imgMercadoFuturoToolIrTrade: imageSharp(fluid: {originalName: { regex: "/img-mercado-futuro-tool-irtrade/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerTurbineSeusGanhosNoDayTrade: imageSharp(fluid: {originalName: { regex: "/banner-turbine-seus-ganhos-no-day-trade/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
