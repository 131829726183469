import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { SectionWrapper, Card } from './style'

const MelhoresFerramentasInvestPro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper id='tenha-as-melhores-ferramentas-com-o-investpro'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 mb-3 mb-md-4 mb-xl-5'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-700 mb-3 text-md-center'>Tenha as melhores ferramentas com o InvestPro</h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--400 fw-400 mb-0 text-md-center'>
              <span className='d-xl-block'>Com a assinatura do InvestPro, você tem acesso as melhores ferramentas e conteúdos exclusivos que podem</span> potencializar seus 
              <a
                href='https://inter.co/pra-voce/investimentos/'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    element_action: 'click button',
                    element_name: 'investimentos',
                    section_name: 'Tenha as melhores ferramentas com o InvestPros',
                    redirect_url: 'https://inter.co/pra-voce/investimentos/',
                  })
                }}
              >
                <span className='text-orange--extra fw-600'>
                  {' '}investimentos.
                </span>
              </a>
            </p>
          </div>
          <div className='col-12 col-md-4 mb-3 mb-md-0'>
            <Card className='bg-white roundered-3 px-4 py-4 py-md-3'>
              <div className='mb-3'><OrangeIcon size='MD' color='#6A6C72' icon='dollar' /></div>
              <div>
                <h2 className='fs-20 fs-md-24 fs-lg-28 lh-24 lh-md-28 text-grayscale--400 fw-400 font-citrina mb-lg-4'>Plano Mensal</h2>
                <p className='fs-28 fs-lg-40 lh-33 text-grayscale--500 fw-700 font-citrina mb-lg-4'>R$ 59,90<span className='fs-20 fs-lg-24'>/mês</span></p>
                <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-20 lh-md-22 text-grayscale--500 fw-500'>e teste gratuito por 7 dias.</p>
              </div>
            </Card>
          </div>
          <div className='col-12 col-md-4 mb-3 mb-md-0'>
            <Card className='bg-white roundered-3 px-4 py-4 py-md-3'>
              <div className='mb-3'><OrangeIcon size='MD' color='#6A6C72' icon='dollar' /></div>
              <div>
                <h2 className='fs-20 fs-md-24 fs-lg-28 lh-24 lh-md-28 text-grayscale--400 fw-400 font-citrina mb-lg-4'>Plano Semestral</h2>
                <p className='fs-28 fs-lg-40 lh-33 text-grayscale--500 fw-700 font-citrina mb-lg-4'>R$ 54,90<span className='fs-20 fs-lg-24'>/mês</span></p>
                <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-20 lh-md-22 text-grayscale--500 fw-500'>e teste gratuito por 7 dias.</p>
              </div>
            </Card>
          </div>
          <div className='col-12 col-md-4 mb-md-0'>
            <Card className='bg-white roundered-3 px-4 py-4 py-md-3'>
              <div className='mb-3'><OrangeIcon size='MD' color='#6A6C72' icon='dollar' /></div>
              <div>
                <h2 className='fs-20 fs-md-24 fs-lg-28 lh-24 lh-md-28 text-grayscale--400 fw-400 font-citrina mb-lg-4'>Plano Anual</h2>
                <p className='fs-28 fs-lg-40 lh-33 text-grayscale--500 fw-700 font-citrina mb-lg-4'>R$ 49,90<span className='fs-20 fs-lg-24'>/mês</span></p>
                <p className='fs-14 fs-md-16 fs-lg-18 lh-16 lh-md-20 lh-md-22 text-grayscale--500 fw-500'><span className='fw-700'>com Cashback</span> e teste gratuito por 30 dias.</p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default MelhoresFerramentasInvestPro
