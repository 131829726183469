import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import Layout from 'src/layouts/BaseLayout'

import useDomReady from 'src/hooks/window/useDomReady'

import MercadoFuturoRightForm from 'src/components/UI/Forms/MercadoFuturoRightForm'
import ModalBaixeAppJson from 'src/components/UI/Forms/MercadoFuturoRightForm/modalBaixeAppData.json'
import ModalOperarJson from 'src/components/UI/Forms/MercadoFuturoRightForm/modalOperarData.json'
import ModalSmarttBotJson from 'src/components/UI/Forms/MercadoFuturoRightForm/modalSmarttBotData.json'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import AindaNaoInvest from './sections/AindaNaoInvest/_index'
import ComoFuncionaMercadoFuturo from './sections/ComoFuncionaMercadoFuturo/_index'
import ConfiraMargensReduzidas from './sections/ConfiraMargensReduzidas/_index'
import ConfiraQuandoNegociar from './sections/ConfiraQuandoNegociar/_index'
import ContratoFuturo from './sections/ContratoFuturo/_index'
import FaqMercadoFuturo from './sections/FaqMercadoFuturo/_index'
import InvestPro from './sections/InvestPro/_index'
import MelhoresBeneficiosSuperApp from './sections/MelhoresBeneficiosSuperApp/_index'
import MelhoresFerramentasInvestPro from './sections/MelhoresFerramentasInvestPro/_index'
import ValoresMercadoFuturo from './sections/ValoresMercadoFuturo/_index'
import Header from './sections/hero/_index'

interface IModalBaixeApp {
  preTitle: string;
  posTitle: string;
  imgqrcode: string;
  link: string;
  linkTitle: string;
}

interface IModalOperar {
  preTitle: string;
  posTitle: string;
  imgqrcode: string;
  link: string;
  linkTitle: string;
}

interface IModalSmarttBot {
  preTitle: string;
  posTitle: string;
  imgqrcode: string;
  link: string;
  linkTitle: string;
}

const MercadoFuturo = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ isModalOpen1, setIsModalOpen1 ] = useState(false)
  const [ isModalOpen2, setIsModalOpen2 ] = useState(false)
  const [ modalBaixeApp, setModalBaixeApp ] = useState('qrcodeMFbaixeapp')
  const [ modalOperar, setModalOperar ] = useState('qrInvestMercadoFuturoOperar')
  const [ modalSmarttBot, setModalSmarttBot ] = useState('qrcodeMFSamrttBot')
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_14',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const ModalBaixeAppArray: IModalBaixeApp[] = ModalBaixeAppJson
  const ModalOperarArray: IModalOperar[] = ModalOperarJson
  const ModalSmarttBotArray: IModalSmarttBot[] = ModalSmarttBotJson

  const baixeAppData = ModalBaixeAppArray.find((item: IModalBaixeApp) => modalBaixeApp === item.imgqrcode)
  const operarData = ModalOperarArray.find((item: IModalOperar) => modalOperar === item.imgqrcode)
  const smarttBot = ModalSmarttBotArray.find((item: IModalSmarttBot) => modalSmarttBot === item.imgqrcode)

  const openMercadoFuturoRightForm = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <MercadoFuturoRightForm closeModal={() => setIsModalOpen(false)} imgqrcode={baixeAppData?.imgqrcode} link={baixeAppData?.link} linkTitle={baixeAppData?.linkTitle} preTitle={baixeAppData?.preTitle} posTitle={baixeAppData?.posTitle} />
    </Modal>
  )
  const openMercadoFuturoRightForm1 = domReady && (
    <Modal isModalOpen={isModalOpen1} setIsModalOpen={setIsModalOpen1} locationToRender={document.body}>
      <MercadoFuturoRightForm closeModal={() => setIsModalOpen1(false)} imgqrcode={operarData?.imgqrcode} link={operarData?.link} linkTitle={operarData?.linkTitle} preTitle={operarData?.preTitle} posTitle={operarData?.posTitle} />
    </Modal>
  )
  const openMercadoFuturoRightForm2 = domReady && (
    <Modal isModalOpen={isModalOpen2} setIsModalOpen={setIsModalOpen2} locationToRender={document.body}>
      <MercadoFuturoRightForm closeModal={() => setIsModalOpen2(false)} imgqrcode={smarttBot?.imgqrcode} link={smarttBot?.link} linkTitle={smarttBot?.linkTitle} preTitle={smarttBot?.preTitle} posTitle={smarttBot?.posTitle} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openMercadoFuturoRightForm}
        {openMercadoFuturoRightForm1}
        {openMercadoFuturoRightForm2}
        <Header isModalOpen={isModalOpen1} setIsModalOpen={setIsModalOpen1} setModalOperar={setModalOperar} />
        <ContratoFuturo isModalOpen={isModalOpen1} setIsModalOpen={setIsModalOpen1} setModalOperar={setModalOperar} />
        <MelhoresBeneficiosSuperApp />
        <ComoFuncionaMercadoFuturo isModalOpen={isModalOpen1} setIsModalOpen={setIsModalOpen1} setModalOperar={setModalOperar} />
        <ValoresMercadoFuturo />
        <MelhoresFerramentasInvestPro />
        <InvestPro />
        <ConfiraQuandoNegociar />
        <ConfiraMargensReduzidas />
        <AindaNaoInvest isModalOpen={isModalOpen1} setIsModalOpen={setIsModalOpen1} setModalOperar={setModalOperar} />
        <FaqMercadoFuturo faq={pageContext.structuredData.faq} />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          operationalLink='https://inter.co/inter-dtvm/'
          section='dobra_12'
        />
      </Layout>
    </Wrapper>
  )
}

export default MercadoFuturo
