import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;

  @media ${device.desktopLG}{
    height: 576px;
  }

  @media ${device.desktopXL}{
    height: 690px;
  }

  .react-multi-carousel-track {
     padding: 0 0 8px 0;
  }
  
  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button { 
        background-color: #FF7A00;
      }
    }
  }  

  .react-multiple-carousel__arrow--right {
    svg {
      fill: #FF7A00;
    }
    @media ${device.tablet}{
      right: 172px;
    }

    @media ${device.desktopLG}{
      right: 202px;
    }

    @media ${device.desktopXL}{
      right: 302px;
    }

    @media ${device.desktopXXXL}{
      right: 372px;
    }
  }
  
  .react-multiple-carousel__arrow--left {
    svg {
      fill: #FF7A00;
    }
    @media ${device.tablet}{
      left: 162px;
    }

    @media ${device.desktopLG}{
      left: 182px;
    }

    @media ${device.desktopXL}{
      left: 272px;
    }

    @media ${device.desktopXXXL}{
      left: 372px;
    }
  }
`
