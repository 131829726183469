import React from 'react'
import Img from 'gatsby-image'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import BreadCrumb from 'src/components/Breadcrumb'
import OpenVideo from 'src/components/OpenVideo'

import { ContentHeader, ButtonVideo } from './style'
import usePageQuery from '../../pageQuery'
interface IHeroProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  setModalOperar: (state: string) => void;
}

const Hero = ({ isModalOpen, setIsModalOpen, setModalOperar }: IHeroProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = () => {
    setModalOperar('qrInvestMercadoFuturoOperar')
    setIsModalOpen(!isModalOpen)
  }

  return (
    <ContentHeader className='py-5 py-md-0 pb-md-4 d-flex position-relative align-items-center'>
      <div className='container px-3'>
        <div className='d-flex align-items-center'>
          <div className='col-12 col-md-6 col-xl-5 px-0'>
            <div className='d-block d-md-none'>
              <OpenVideo
                link='https://www.youtube.com/embed/cx5OcPJTcKw'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click video',
                    element_name: '',
                    section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
                  })
                }}
              >
                <ButtonVideo className=''>
                  <Img className='float-right' fluid={data.PlayMercadoFuturo.fluid} alt='Assista ao vídeo' />
                </ButtonVideo>
              </OpenVideo>
            </div>
            <div className='pt-5 mt-lg-5'>
              <BreadCrumb
                sectionName='Global Account Business - Sua conta internacional PJ'
                type={<OrangeIcon color='#6A6C72' size='MD' icon='home' className='mr-1' />}
                product='Investimentos'
                text='Mercado Futuro'
                link='/pra-voce/investimentos/'
              />
            </div>
            <h1 className='fs-32 fs-lg-40 fs-xl-48 lh-33 lh-lg-50 text-grayscale--500 my-3 my-xl-4 fw-700'>
              <span className='d-block'>Mercado Futuro:</span><span className='d-lg-block'>potencialize seus</span> <span className='d-lg-block'>ganhos como Day</span> Trader 
            </h1>
            <p className='fs-14 fs-lg-16 lh-17 lh-lg-19 text-grayscale--400 fw-400'>
              <span className='d-lg-block'>Maximize seu sucesso como Day Trader operando</span> <span className='d-lg-block'>minicontratos de forma eficiente e com corretagem</span> zero através do RLP ativo. 
            </p>
            <button
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
              onClick={() => {
                handleClick()
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: ' Operar agora',
                  section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
                })
              }}
            >
              Operar agora
            </button>
          </div>
          <div className='col-12 col-md-6 col-lg-5 col-xl-6 pl-lg-0 d-none d-lg-block'>
            <OpenVideo
              link='https://www.youtube.com/embed/cx5OcPJTcKw?&autoplay=1'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  element_action: 'click button',
                  element_name: 'null',
                  section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
                })
              }}
            >
              <ButtonVideo className='mt-4 mb-5 m-md-0'>
                <Img className='float-right' fluid={data.PlayMercadoFuturo.fluid} alt='Assista ao vídeo' />
              </ButtonVideo>
            </OpenVideo>
          </div>
          <div className='col-12 col-md-6 col-lg-5 pl-lg-0 d-none d-md-block d-lg-none'>
            <OpenVideo
              link='https://www.youtube.com/embed/cx5OcPJTcKw?&autoplay=1'
              onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                element_name: 'null',
                section_name: 'Corretagem zero para operar minicontratos com RLP ativo',
              })
              }}
            >
              <ButtonVideo className='mt-4 mb-5 m-md-0'>
                <Img className='float-right' fluid={data.PlayMercadoFuturo.fluid} alt='Assista ao vídeo' />
              </ButtonVideo>
            </OpenVideo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Hero
