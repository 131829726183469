import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import Img from 'gatsby-image'
import usePageQuery from './pageQuery'
import axios from 'axios'
import { Link } from 'gatsby'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { useLocation } from '@reach/router'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import MSG from './msgAccount.json'

import useWidth from 'src/hooks/window/useWidth'

import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'

import errorImg from 'src/assets/images/shared/erro-pig.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import BaixeOApp from './assets/images/qr-invista-mercado-futuro-operar.png'
import sucesso from '../images/sucesso.png'

import { Container, CloseButton, QRCodeDiv, SucessoDiv } from './style'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'

type OpenAccountRightFormProps = {
  closeModal: () => void;
  customOrigin?: string;
  preTitle?: string;
  posTitle?: string;
  imgqrcode?: string;
  link?: string;
  linkTitle?: string;
}
interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function OpenAccountRightForm ({ closeModal, customOrigin, imgqrcode = 'operarMercadoFuturoQrcode', link, linkTitle, preTitle, posTitle }: OpenAccountRightFormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const location = useLocation()
  const WIDTH_MD = 1024
  const WIDTH_SM = 578
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ qrcode, setRQrcode ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ filled, setFilled ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ sendDatalayerEvent ] = useDataLayer()

  const publicOfferings = location.pathname.includes('/pra-voce/investimentos/ofertas-publicas')
  const InvestmentCommunity = location.pathname.includes('/pra-voce/investimentos/comunidade-de-investimentos')

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: customOrigin || 'site-institucional',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
      } else {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        setSent(true)
        sendDataLayerEvent('form_submitted')
        sendDataLayerEvent({
          section: 'm_0',
          element_action: 'submit',
          element_name: 'Abra sua conta digital e invista no mercado futuro hoje mesmo',
        })
        setLoading(false)
        sendCDPFormData({ formName: 'Formulario de investimentos', cpf: formData.cpf, email: formData.email })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://intergo.app/7820e737'
    } else {
      setSent(false)
      setFilled(true)
    }
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://intergo.app/7820e737'
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    setRQrcode(true)
    sendDataLayerEvent({
      section: 'm_0',
      element_action: 'submit',
      element_name: 'Abra sua conta digital e invista no mercado futuro hoje mesmo',
    })
  }
  const data = usePageQuery()

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt='Imagem de erro' />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button onClick={handleReturn} title='Clique aqui para voltar' className='bg-transparent border-0 fw-600'>
                Clique aqui
              </button>
              para voltar.
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center'>
            <SucessoDiv className='d-none d-md-flex justify-content-center'>
              <Img fluid={data.MercadoFuturoSuccess.fluid} alt='Imagem de sucesso' />
            </SucessoDiv>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600'>
              Recebemos os seus dados.
            </p>
            <h3 className='fs-24 lh-28 text-grayscale--500 mb-4 fw-600'>
              {preTitle} <a href={link} className='text-orange--extra'> {linkTitle} </a>
              {posTitle}
            </h3>
          </div>
        </Container>
      )}
      { qrcode && (
        <Container isModal={closeModal} className='d-flex align-items-center qrcode'>
          {closeButton}
          <div className='col-12'>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600'>
              Recebemos os seus dados.
            </p>
            <h3 className='col-10 fs-24 lh-28 text-grayscale--500 pl-0 mb-4 fw-600'>
              {preTitle} <span className='text-orange--extra'> {linkTitle} </span>
              {posTitle}
              <span className='d-block'>{publicOfferings ? ' e invista nas Ofertas Públicas.' : ''}</span>
            </h3>
            <QRCodeDiv className='d-none d-md-flex justify-content-center'>
              <Img fluid={data[imgqrcode].fluid} alt='QR code para o app do Inter' />
            </QRCodeDiv>
            <div className='col-12 d-none d-md-block'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                Abra a câmera do seu celular e aponte para o QR Code acima.
                Você irá direto pra área de investimentos do app. É rápido e fácil!
              </p>
            </div>
          </div>
        </Container>
      )}
      { underAge && (
        <Container isModal={closeModal} className='d-flex align-items-center under-age'>
          {closeButton}
          <div className='col-12 text-md-center text-lg-left'>
            <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
              Vimos que você tem menos de 18 anos.
            </h3>
            <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>
                Conheça nossa
                <span
                  className='text-orange--extra cursor-pointer'
                  onClick={asUnderAge}
                > Conta Kids
                </span>,
              </span>
              que também é completa, digital e gratuita.
            </p>
            <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
              <img src={sucesso} alt='Sucesso' />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block text-center'>
              <img src={BaixeOApp} alt='Baixe o App Inter' />
            </div>
            <div className='col-12 d-none d-lg-block px-0'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e
                clicar em “Abrir conta completa e gratuita”. É rápido e fácil!
              </p>
              <p className='fs-14 lh-17 text-left'>
                De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos
                sem o consentimento dos responsáveis.
              </p>
            </div>
          </div>
        </Container>
      )}
      {filled && (
        <Container isModal={closeModal} className='d-flex align-items-center filled'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 fw-600 text-grayscale--500'>
              Baixe o app e vá em <br /> “Abrir uma conta gratuita”
            </h3>
            <p className='fs-16 lh-20 text-grayscale--400 mb-5'>
              {MSG.filled}
            </p>
            <div className='col-12'>
              <img src={BaixeOApp} alt='Baixe o App Inter' />
            </div>
          </div>
        </Container>
      )}
      <Container isModal={closeModal} className={`${error || sent || qrcode || filled || underAge ? 'd-none' : 'd-flex'} align-items-center`}>
        {closeButton}
        <div className='row'>
          <div className='col-11 col-md-10 py-md-4 pl-md-4 pt-lg-0'>
            <h2 className='fs-24 fs-md-32 lh-30 lh-md-40 mb-2 mt-5 text-grayscale--500'>
              Abra sua conta digital e invista no mercado futuro hoje mesmo
              <span className='d-block'>{publicOfferings ? ' e invista nas Ofertas Públicas.' : ''}</span>
              <span className='d-block'>{InvestmentCommunity ? ' e crie a sua comunidade.' : ''}</span>
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-12 lh-15 fw-600'>Telefone</label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-12 lh-15 fw-600'>CPF</label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 pb-1'>
                  <label htmlFor='dataNascimento' className='fs-12 lh-15 fw-600'>Data de Nascimento</label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 text-center'>
                  <div className='col-12 text-left d-flex flex-column px-0 pr-md-3'>
                    <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' />
                  </div>
                  <div className='row align-items-center justify-content-center'>
                    <div className='col-md-6'>
                      {windowWidth <= WIDTH_SM
                      ? (
                        <a
                          href={link}
                          className='btn bdr btn-white text-orange--extra mb-4 mb-md-0 fs-14 fw-700 rounded-2 mx-auto'
                          onClick={() => {
                          sendDatalayerEvent({
                            section: 'm_0',
                            element_action: 'click button',
                            element_name: 'Já sou cliente Inter',
                            section_name: 'Com a nossa assessoria, você chega mais longe',
                            redirect_url: link,
                          })
                          }}
                        >
                          Já sou cliente Inter
                        </a>
                      ) : (
                        <button
                          onClick={handleClick}
                          className='btn bdr btn-white text-orange--extra mb-4 mb-md-0 fs-14 fw-700 rounded-2 mx-auto'
                        >
                          Já sou cliente Inter
                        </button>)
                      }
                    </div>
                    <div className='col-md-6'>
                      <button
                        type='submit' title='Enviar contato' disabled={!accept || loading}
                        className='btn btn-orange--extra fs-14 fw-700 rounded-2 mx-auto text-white text-none'
                      >
                        {loading ? 'Enviando...' : 'Continuar'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className='text-center pt-3'>
              <button
                onClick={asFilled}
                className='text-orange--extra fw-600 bg-transparent border-0'
              />
              {
                InvestmentCommunity && (
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-16 fw-600 lh-20 text-orange--extra pt-4 d-block'
                  > Saiba mais sobre Investimentos
                  </Link>
                )
              }
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default OpenAccountRightForm
