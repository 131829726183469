import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Card } from './style'

const ValoresMercadoFuturo = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

    return (
      <Section className='py-5 pl-lg-5 pr-lg-4'>
        <div className='container py-xl-4'>
          <div className='row align-items-center justify-content-center pr-lg-4'>
            <div className='col-12'>
              <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-grayscale--500 text-center fw-700'>Valores do mercado futuro no Inter</h2>
            </div>
            <Card className='col-12 px-3 my-md-3'>
              <h2 className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-32 text-grayscale--500 text-md-center my-3'>Operações via Home Broker ou plataformas</h2>
              <p className='fs-12 fs-md-16 fs-lg-20 lh-14 lh-md-20 lh-lg-25 text-grayscale--500 text-md-center font-citrina'>Day Trade em Índice e Dólar</p>
            </Card>
            <div className='col-12'>
              <div className='row mb-md-4'>
                <div className='col-6 h-100 pl-3 my-3'>
                  <h3 className='fs-12 fs-md-16 fs-lg-20 fs-xl-24 lh-15 lh-md-20 lh-lg-25 text-md-right fw-400'>Com RLP</h3>
                  <p className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-md-right text-grayscale--500 fw-700 mb-0 font-citrina'>Custo zero</p>
                </div>
                <div className='col-6 h-100 border-md-left my-3'>
                  <h3 className='fs-12 fs-md-16 fs-lg-20 fs-xl-24 lh-15 lh-md-20 lh-lg-25 text-left fw-400'>Sem RLP</h3>
                  <p className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-left text-grayscale--500 fw-700 mb-0 font-citrina'>
                    R$ 0,70
                    <span> por <span className='d-block d-md-inline'>contrato</span></span>
                  </p>
                </div>
              </div>
            </div>
            <Card className='col-12 px-3 my-md-3'>
              <h2 className='fs-14 fs-md-20 fs-lg-24 fs-md-20 lh-17 lh-md-25 lh-md-25 lh-lg-32 text-grayscale--500 text-md-center my-3'>Operações via mesa</h2>
              <p className='fs-12 fs-md-16 fs-lg-20 lh-14 lh-md-20 lh-lg-25 text-grayscale--500 text-md-center font-citrina'>Day Trade e Swing Trade</p>
            </Card>
            <div className='col-12'>
              <div className='row mb-3 mb-md-4'>
                <div className='col-6 h-100 pl-3 mt-3'>
                  <h3 className='fs-12 fs-md-16 fs-lg-20 fs-xl-24 lh-15 lh-md-20 lh-lg-25 text-md-right fw-400'>Minicontrato</h3>
                  <p className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-md-right text-grayscale--500 fw-700 mb-0 font-citrina'> R$ 15,00
                    <span> por <span className='d-block d-md-inline'>contrato</span></span>
                  </p>
                </div>
                <div className='col-6 h-100 border-md-left mt-3'>
                  <h3 className='fs-12 fs-md-16 fs-lg-20 fs-xl-24 lh-15 lh-md-20 lh-lg-25 text-left fw-400'>Contratos cheios</h3>
                  <p className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-left text-grayscale--500 fw-700 mb-0 font-citrina'>
                    R$ 100,00
                    <span> por <span className='d-block d-md-inline'>contrato</span></span>
                  </p>
                </div>
              </div>
            </div>
            <Card className='col-12 px-3 mt-md-4 mb-md-3'>
              <h2 className='fs-14 fs-md-20 fs-lg-24 fs-md-20 lh-17 lh-md-25 lh-md-25 lh-lg-32 text-grayscale--500 text-md-center my-3'>Zeragem compulsória</h2>
              <p className='fs-12 fs-md-16 fs-lg-20 lh-14 lh-md-20 lh-lg-25 text-grayscale--500 text-md-center font-citrina'>Day Trade e Swing Trade</p>
            </Card>
            <div className='col-12'>
              <div className='row mb-md-4'>
                <div className='col-6 h-100 pl-3 mt-3'>
                  <h3 className='fs-12 fs-md-16 fs-lg-20 fs-xl-24 lh-15 lh-md-20 lh-lg-25 text-md-right fw-400'>Minicontrato</h3>
                  <p className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-md-right text-grayscale--500 fw-700 mb-0 font-citrina'>R$ 15,00
                    <span> por <span className='d-block d-md-inline'>contrato</span></span>
                  </p>
                </div>
                <div className='col-6 h-100 border-md-left mt-3'>
                  <h3 className='fs-12 fs-md-16 fs-lg-20 fs-xl-24 lh-15 lh-md-20 lh-lg-25 text-left fw-400'>Contratos cheios</h3>
                  <p className='fs-14 fs-md-20 fs-lg-24 lh-17 lh-md-25 lh-lg-30 text-left text-grayscale--500 fw-700 mb-0'>
                    R$ 100,00
                    <span> por <span className='d-block d-md-inline'>contrato</span></span>
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-9 col-xl-7 px-0 pt-md-5'>
              <hr className='d-block d-md-none' />
              <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-md-center fw-700 mb-0'> Consulte a
                <a
                  className='text-orange--base'
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Tabela Operacional Básica',
                      section_name: 'Valores do mercado futuro no Inter',
                      redirect_url: 'https://www.b3.com.br/pt_br/produtos-e-servicos/tarifas/',
                    })
                  }}
                >
                  {' '}Tabela Operacional Básica
                </a>
                {' '}para custos dos demais ativos operados via mesa
              </p>
            </div>
          </div>
        </div>
      </Section>
    )
}

export default ValoresMercadoFuturo
