import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      qrInvestMercadoFuturoOperar: imageSharp(fluid: { originalName: { regex: "/qr-code-mercado-futuro/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      qrcodeMFSamrttBot: imageSharp(fluid: { originalName: { regex: "/qr-invista-mercado-futuro-smarttbot/" }}) {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      MercadoFuturoSuccess: imageSharp(fluid: { originalName: { regex: "/mercado-futuro-success/" }}) {
        fluid(maxWidth: 65, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
