import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
@media ${device.tablet} {
 .border-md-left {
 border-left-style: dashed;
 }
}
`
export const Card = styled.div`
background-color: ${grayscale[100]};

@media ${device.tablet} {
 background-color: white;   
 border: solid 2px #DEDFE4;
 border-radius: 8px;
}
`
