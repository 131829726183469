import styled from 'styled-components'
import { gray } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`

  h2 {
    @media ${device.tablet}{
      height: 50px;
    }

    @media ${device.desktopLG}{
      height: 36px;
    }
  }

  @media ${device.desktopXL}{
    height: 567px;
  }  
  .font-citrina {
    font-family: 'Citrina';
  }

  display: flex;
  align-items: center;
  background-color: ${gray[400]};
`

export const Card = styled.div`
  border: 1px solid ${gray[200]};
  border-radius: 6px;

  @media ${device.tablet}{
    height: 270px;
  }

  @media ${device.desktopLG}{
    height: 290px;
  }
`
