import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Card = styled.div`
  @media ${device.tablet} {
    border: solid 1px #DEDFE4;
    border-radius: 8px;
    height: 216px !important; 
    max-width: 226px !important;
    margin-bottom: 24px;
    margin-right: 24px;
  }

  @media ${device.desktopLG} {
    height: 233px !important;
    max-width: 298px !important;
  }

  @media ${device.desktopXL} {
    height: 208px !important;
    max-width: 360px !important;
  }
`
export const TitleCard = styled.div`
  padding-bottom: 20px;
  padding-top: 8px;
`
