import React from 'react'
import Img from 'gatsby-image'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from '../../pageQuery'

import { Section, ImgSize } from './style'

interface IComoFuncionaMercadoFuturoProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  setModalOperar: (state: string) => void;
}

const AindaNaoInvest = ({ isModalOpen, setIsModalOpen, setModalOperar }: IComoFuncionaMercadoFuturoProps) => {
  const data = usePageQuery()
  const handleClick = () => {
    setModalOperar('qrInvestMercadoFuturoOperar')
    setIsModalOpen(!isModalOpen)
  }
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section className='py-5 d-flex align-items-xl-center'>
      <div className='container py-xl-4'>
        <div className='row align-items-center'>
          <ImgSize className='col-12 col-md-6 col-lg-5 px-md-0 pl-lg-3'>
            <Img fluid={data.imgMercadoFuturoGrafico.fluid} alt='Imagem de um gráfico' />
          </ImgSize>
          <div className='col-12 col-md-6 col-lg-6 offset-lg-1 offset-xl-2 col-xl-5 pr-0 pr-xl-3 mt-3'>
            <h2 className='fs-24 fs-lg-40 lh-30 lh-lg-50 text-grayscale--500 mb-3 pb-1 fw-700'>Ainda não investe <span className=''>pelo Inter?</span></h2>
            <p className='fs-14 fs-lg-18 lh-lg-22 lh-17 text-grayscale--400 mb-4'>Conheça os benefícios da plataforma do Inter e <span className=''>comece a investir</span></p>
            <div className='col-12 px-0 mt-4 mt-md-0 mb-md-3'>
              <div className='d-flex align-items-center'>
                <OrangeIcon size='MD' color='#6A6C72' icon='home-broker' />
                <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--500 mb-0 pl-3'><strong>Home Broker gratuito em português</strong></p>
              </div>
            </div>
            <div className='col-12 px-0 mt-4 mt-md-0'>
              <div className='d-flex align-items-center'>
                <OrangeIcon size='MD' color='#6A6C72' icon='inter-research' />
                <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-19 lh-xl-22 text-grayscale--500 mb-0 pl-3'><strong>Conteúdos exclusivos sobre investimentos</strong></p>
              </div>
            </div>
            <button
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 mt-4 mt-xl-5 text-none'
              onClick={() => {
                handleClick()
                  sendDatalayerEvent({
                    section: 'dobra_11',
                    element_action: 'click button',
                    element_name: ' Abra sua conta',
                    section_name: 'Ainda não investe pelo Inter?',
                  })
              }}
            >
              Abra sua conta
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AindaNaoInvest
