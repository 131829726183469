import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

  @media ${device.desktopXL} {
    height: 640px;
  }

   .btn {
     max-width: none;
   }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.tablet} {
      width: 334px;
    }  
    @media ${device.desktopLG} {
      width: 380px;
    } 
    @media ${device.desktopXL} {
      width: 456px;
    }  
  }
`